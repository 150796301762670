class SliderActus {

  constructor()
  {
    this.class = '.section-media-text .line-media-slider .line-media-container'
    this.$slider = $(this.class)

    if ( this.$slider.length === 0 ) return

    var slider = [{
      slider: this.$slider,
      args: {
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: '<a href="#" class="slick-prev"><i class="icon icon-arrow left"></i></a>',
        nextArrow: '<a href="#" class="slick-next"><i class="icon icon-arrow right"></i></a>',
        focusOnSelect: true,
        arrows: false,
        dots: true,
      }
    }]
    new SliderController( slider );
  }

}
new SliderActus()