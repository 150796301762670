class GlobalController {

    static getWindowWidthCategory() {
        var window_width = window.outerWidth,
            // xs = 0,
            sm = 576,
            md = 768,
            lg = 992,
            xl = 1200;

        if ( window_width >= xl ) {
            return 'xl';
        } else if ( window_width >= lg ) {
            return 'lg';
        } else if ( window_width >= md ) {
            return 'md';
        } else if ( window_width >= sm ) {
            return 'sm';
        } else {
            return 'xs';
        }
    }

}
