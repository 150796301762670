class StickyController {

  constructor( $elt ) {
    this.$elt = $elt

    if (this.$elt.length) {
      this.init()
    }
  }

  init() {
    this.$window = $(window)
    this.widthWindow = this.$window.width()

    let $container = $(this.$elt.data('sticky-container'))
    let offsetTop = this.$elt.data('sticky-offset-top')
    let paddingBottom = this.$elt.data('sticky-bottom')
    let paddingTop = this.$elt.data('sticky-top')
    let minWidth = this.$elt.data('sticky-min-width')

    if ($container.length) this.$container = $container
    else this.$container = this.$elt.parent()
    if ($.isNumeric(offsetTop)) this.offsetTop = offsetTop
    else this.offsetTop = 0
    if ($.isNumeric(paddingTop)) this.paddingTop = paddingTop
    else this.paddingTop = 0
    if ($.isNumeric(paddingBottom)) this.paddingBottom = paddingBottom
    else this.paddingBottom = 0
    if ($.isNumeric(minWidth)) this.minWidth = minWidth
    else this.minWidth = 0

    /* Sticky menu event */
    this.$window.on('load', this.handleLoad.bind(this));

    /* Add element style */
    this.$container.css('position', 'relative')
  }

  handleLoad() {
    this.refreshResponsive();
    this.refreshPosition();
    this.sticky();

    this.$window.on('resize', this.handleResize.bind(this));
    this.$window.on('scroll', this.handleScroll.bind(this));
  }
  
  handleScroll() {
    this.sticky();
  }
  
  handleResize() {
    this.widthWindow = this.$window.width()

    this.refreshResponsive();
    this.refreshPosition();
    this.sticky();
  }

  refreshResponsive() {
    if (this.widthWindow > this.minWidth) {
      this.activeSticky = true
    } else {
      this.activeSticky = false
    }
  }

  refreshPosition() {
    if (this.$elt.length && this.activeSticky) {
      this.$elt.removeAttr('style')
      this.eltHeight = this.$elt.height()
      this.containerHeight = this.$container.height()
      this.containerTopPosition = this.$container.offset().top
      this.containerLeftPosition = this.$container.offset().left
      this.containerBottomPosition = this.containerTopPosition + this.$container.height();
      this.eltTopPositionBody = this.$elt.offset().top
      this.eltLeftPositionBody = this.$elt.offset().left
      this.eltWidth = this.$elt.width()
      this.eltTopPositionContainer = this.eltTopPositionBody - this.containerTopPosition
      this.eltLeftPositionContainer = this.eltLeftPositionBody - this.containerLeftPosition
      this.eltMaxTopPositionContainer = this.containerHeight - this.eltHeight - this.paddingBottom
    }
  }

  sticky() {
    var windowsTop = $(window).scrollTop() + this.offsetTop + this.paddingTop

    if (  windowsTop >= this.containerTopPosition + this.paddingTop
      && windowsTop <= (this.containerTopPosition + this.eltMaxTopPositionContainer)
      && this.activeSticky
    ) {
      // Middle
      this.$elt.css('position', 'fixed')
      this.$elt.css('top', this.paddingTop + this.offsetTop)
      this.$elt.css('left', this.eltLeftPositionBody)
      this.$elt.css('width', this.eltWidth)
    } else if (windowsTop >= (this.containerTopPosition + this.eltMaxTopPositionContainer) && this.activeSticky) {
      // Bottom
      this.$elt.css('position', 'absolute')
      this.$elt.css('top', this.eltMaxTopPositionContainer)
      this.$elt.css('left', this.eltLeftPositionContainer)
      this.$elt.css('width', this.eltWidth)
    } else if (windowsTop <= this.containerTopPosition + this.paddingTop) {
      // Top
      this.$elt.removeAttr('style')
    }
  }

}
