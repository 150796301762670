class TrackingFormController
{
    constructor()
    {
        if ( typeof acf !== 'undefined' ) {
            if ( !acf.get('is_admin') ) {
                acf.addAction('validation_success',  this.validationSuccessAcfeForm.bind(this));
            }
        }
    }

    validationSuccessAcfeForm( $el, el ) {
        this.$el = $el;
        this.el = el;

        let vars = {};

        if( $el.find('#vah_form_dl').length < 1 ) return;

        vars["form-name"] = $el.find('#vah_form_dl').val();
        
        let dataAttributes =  $el.find('#vah_form_dl')[0].attributes;

        for (let i = 0; i < dataAttributes.length; i++){

            let expr = /^(data\-dl\-).+/;
            let name = dataAttributes[i].nodeName;
            let value = dataAttributes[i].nodeValue;
            
            if(expr.test(name)){

                let nameClean = name.replace(/data-dl-/g,'');
                vars[nameClean] = this.formMapField(value);

            }

        }

        this.tagEvent(vars);
        
        //throw new Error();

    }

    formMapField(value){     
        let self = this;

        if (value === undefined || typeof value !== 'string') {
            return value;
        }

        if(value.indexOf('{field:') === -1){
            return value;
        }

        //let matches = value.match(/{field:(.*?)}/g);
        let matches = [...value.matchAll(/{field:(.*?)}/ig)];

        if(!matches.length){
            return value;
        }

        matches.forEach(function(match){

            let field_key = match[1]; // field_123abc or field_name
            let fields;

            if(field_key.indexOf('field_') === -1){ //field_name
                fields = acf.getFields({
                    name: field_key,  
                    parent: self.$el 
                });
            }else{ //field_key
                fields = acf.getFields({
                    key: field_key,  
                    parent: self.$el 
                });
            }

            //No field find in the form for the given key or name
            if(!fields.length) return;

            //Get the value of the field
            let field_value = fields[0].val();

            //Replace the field tag template by the value
            value = value.replace('{field:' + field_key + '}', field_value);
            
        });

        return value;

    }

    tagEvent( vars ) {

        window.dataLayer = dataLayer || [];
        dataLayer.push(vars);
    }
}
