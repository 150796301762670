class MenuController {

  constructor () {
    var self = this
    self.body = jQuery('body')
    self.header = jQuery('header.header')
    self.button_menu = self.header.find('#toggler-menu-burger')
    self.is_active = false
    self.is_hover = false
    self.is_hover_child = false
    self.bp = GlobalController.getWindowWidthCategory()

    // Hover menu with child
    $('header nav > ul > li').hover(
      function () {
        if ( $(this).find('ul').length ) {
          self.is_hover = true
          $('header').addClass('menu-hover')
        }
      }, function (e) {
        self.is_hover = false
        if ( self.bp === 'lg' || self.bp === 'xl' ) {
          setTimeout(function () {
            if (!self.is_hover) {
              $('header').removeClass('menu-hover')
            }
          }, 300)
        }
      }
    )
    // Hover menu child
    $('.header-nav-left .big-menu-items > ul > li').hover(
      function () {
        if ( self.bp === 'lg' || self.bp === 'xl' ) {
          let $elt = $(this)

          if ( $elt.find('.dropdown-menu').length ) {
            self.is_hover_child = true
            $elt.closest('.big-menu-items').parent().find('.container').hide();
          }
        } else {
          self.is_hover_child = false;
        }
      }, function (e) {
        self.is_hover_child = false
        let $item = $(this)
        if ( self.bp === 'lg' || self.bp === 'xl' ) {
          setTimeout(function () {
            if (!self.is_hover_child) {
              $item.closest('.big-menu-items').parent().find('.container').show();
            }
          }, 400)
        }
      }
    )

    $(window).on('resize', self.handleResize.bind(self));
    self.button_menu.on('click', self.handleClickButtonMenu.bind(self))
    $('.wrap-mobile-header a').on('click', self.handleClick.bind(self) )
    $('ul.menu .dropdown-link i').on('click', self.handleClickDropdownLink.bind(self))
    $('.big-menu-back-button').on('click', self.handleClickBackButton.bind(self))
  }

  handleResize (e) {
    this.bp = GlobalController.getWindowWidthCategory()

    if ( this.bp === 'lg' || this.bp === 'xl' ) {
      this.close()
    }
  }

  handleClickButtonMenu (e) {
    e.preventDefault()
    this.toggle()
  }

  handleClick (e) {
    e.preventDefault()
    this.close()
  }

  handleClickDropdownLink (e) {
    var $line = $(e.currentTarget).closest('li')

    if ($line.find('ul').length && this.bp !== 'lg' && this.bp !== 'xl') {
      e.preventDefault()
      $line.addClass('open')
    }
  }

  handleClickBackButton (e) {
    e.preventDefault()
    var $line = $(e.currentTarget).closest('li')

    if ( $line.hasClass('big-menu-back')) {
      $line = $line.parent().closest('li')
    }

    $line.removeClass('open')
    $line.find('.open').removeClass('open')
  }

  toggle () {
    if (this.is_active) this.close()
    else this.open()
  }

  open () {
    this.is_active = true
    this.header.addClass('open menu-hover')
    this.body.addClass('menu-open')
  }

  close () {
    this.is_active = false
    this.header.removeClass('open menu-hover')
    this.header.find('.open').removeClass('open')
    this.body.removeClass('menu-open')
  }

}
