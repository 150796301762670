class ProjectsController {

    constructor( _helpers ) {
        this.$container_archive = $('.archive-project')

        /* Archive projects */
        if ( this.$container_archive.length ) {
            new StickyController($(".archive-project-sticky"))
        }
    }

}
