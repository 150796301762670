class MapController {

  constructor () {
    this.body = $('body')
    this.$archive_project = $('.archive-project')
    this.$projects_map = $('.projects-map')
    this.$projects_map_info = $('.info-map')
    this.$container = $('.archive-project-list')
    this.key_results = 'data-status-id'
    this.markers = []

    if (this.$archive_project.length) {
      this.initArchiveProject()
    }
    if (this.$projects_map.length) {
      this.initProjectMap()
    }
    if (this.$projects_map_info.length) {
      this.initProjectMapInfo()
    }
  }

  initProjectMapInfo () {
    let self = this;
    self.projects_tab = projects_tab_info
    self.$map = document.getElementById('info-map-map')

    self.createMap()
    google.maps.event.addListenerOnce(self.map, 'bounds_changed', function() {
      self.map.setZoom(15);
    });
    self.createMarkersMap()
  }

  initProjectMap () {
    this.$form = $('.projects-map-form')
    this.projects_tab = projects_tab
    this.$map = document.getElementById('projects-map')

    this.$form.on('change submit', this.handleChangeAndSubmitForm.bind(this))
    this.createMap()
    this.createMarkersMap()
  }
  
  initArchiveProject () {
    this.projects_tab = projects_tab_archive
    this.$map = document.getElementById('projects-map-archive')
    this.$form = $('.archive-project-form')

    this.$form.on('change submit', this.handleChangeAndSubmitForm.bind(this))
    $('.card-project-map-link').on('click', this.handleClickCardProjectLocation.bind(this))
    this.createMap()
    this.createMarkersMap()
  }

  handleClickCardProjectLocation (e) {
    e.preventDefault()
    let project_id = $(e.currentTarget).closest('.card-project').attr('id')
    let current_marker

    this.markers.map(function (marker) {
      if (project_id == marker.data.id) current_marker = marker
    })
    if (typeof current_marker === 'object') {
      this.infowindow.setContent(current_marker.card_content)
      this.infowindow.open(this.map, current_marker)
    }
  }

  createMarkersMap()
  {
    let self = this
    let i = 0
    let bounds = new google.maps.LatLngBounds()
    this.infowindow = new google.maps.InfoWindow()

    while (i < self.projects_tab.length) {
      let content_info_window =
        '<div class="info-window">\n' +
        '<a data-dl="true" data-dl-event="project-click" data-dl-project-name="'+self.projects_tab[i].title+'" data-dl-section-type="Map" class="info-window-absolute" href="'+self.projects_tab[i].link+'"></a>\n' +
        '<div class="info-window-content">\n' +
        '<div class="info-window-title">'+self.projects_tab[i].title+'</div>\n' +
        '<div class="info-window-location">'+self.projects_tab[i].location+'</div>\n' +
        '<hr class="info-window-separator" />\n' +
        '<div class="info-window-type">'+self.projects_tab[i].type+'</div>\n' +
        '<div class="info-window-status">'+self.projects_tab[i].status+'</div>\n' +
        '</div>\n' +
        '<div class="info-window-image"><img src="'+self.projects_tab[i].image_url+'" src="'+self.projects_tab[i].image_alt+'" /></div>\n' +
        '</div>'

      let marker = new google.maps.Marker({
        position: self.projects_tab[i].position,
        map: self.map,
        title: self.projects_tab[i].title,
        icon: self.projects_tab[i].icon_url,
        data: self.projects_tab[i],
        status_id: self.projects_tab[i].status_id,
        location: self.projects_tab[i].location,
        image_url: self.projects_tab[i].image_url,
        image_alt: self.projects_tab[i].image_alt,
        card_content: content_info_window
      })

      google.maps.event.addListener(marker, 'click', (function (marker, i) {
        return function () {
          //Tracking
          self.tagEvent({
            "event": "map-click",
            "project-name": self.markers[i].title
          });
          self.infowindow.setContent(marker.card_content)
          self.infowindow.open(self.map, marker)
        }
      })(marker, i))

      bounds.extend(self.projects_tab[i].position)
      self.markers.push(marker)
      i++
    }
    this.map.fitBounds(bounds)
  }

  handleChangeAndSubmitForm (e) {
    if (e.type === 'submit') e.preventDefault()

    this.refreshMarkers()
    this.refreshResults()
  }

  refreshMarkers () {
    let self = this
    let $checkbox_checked = this.$form.find(':checked')
    let status = []

    $checkbox_checked.each(function (i) {
      status.push(Number($(this).val()))
      //Tracking
      let vars = {};
      vars["event"]       = "map-filter-click";
      vars["filter-name"] = $(this).attr("dl-name");
      self.tagEvent(vars);
    })

    if (status.length) {
      this.markers.map(function (marker) {
        if (status.includes(marker.status_id)) {
          marker.setMap(self.map)
        } else {
          marker.setMap(null)
        }
      })
    } else {
      this.showHideMarkers(this.markers, true)
    }
  }

  refreshResults () {
    let $checkbox_checked = this.$form.find(':checked')
    let key_results = this.key_results
    let selector = []

    $checkbox_checked.each(function (i) {
      let value = $(this).val()
      selector.push('[' + key_results + '="' + value + '"]')
    })

    if (selector.length > 0) {
      this.$container.find('[' + key_results + ']').hide(50)
      this.$container.find(selector.join(', ')).show(100)
    } else {
      this.$container.find('[' + key_results + ']').show(100)
    }
  }

  showHideMarkers (markers, is_show) {
    let self = this

    if ( is_show ) {
      markers.map(function (marker) {
        marker.setMap(self.map)
      })
    } else {
      markers.map(function (marker) {
        marker.setMap(null)
      })
    }
  }

  createMap() {
    this.map_styles = this.getMapStyles()

    let latlng = new google.maps.LatLng(48.853, 2.35)
    this.map_options = {
      zoom: 14,
      scrollwheel: false,
      mapTypeControl: false,
      center: latlng,
      streetViewControl: false,
      styles: this.map_styles
    }
    this.map = new google.maps.Map(this.$map, this.map_options)
  }

  tagEvent( vars ) {
    window.dataLayer = dataLayer || [];
    dataLayer.push(vars);
  }

  getMapStyles () {
    return [
      {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#444444"
          }
        ]
      },
      {
        "featureType": "administrative.country",
        "elementType": "all",
        "stylers": [
          {
            "visibility": "on"
          },
          {
            "weight": "0.56"
          },
          {
            "color": "#8ec6c1"
          }
        ]
      },
      {
        "featureType": "administrative.province",
        "elementType": "geometry",
        "stylers": [
          {
            "visibility": "on"
          },
          {
            "gamma": "0.91"
          }
        ]
      },
      {
        "featureType": "administrative.province",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "visibility": "on"
          }
        ]
      },
      {
        "featureType": "administrative.province",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "visibility": "on"
          }
        ]
      },
      {
        "featureType": "administrative.locality",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#477b7f"
          }
        ]
      },
      {
        "featureType": "administrative.locality",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#123b65"
          }
        ]
      },
      {
        "featureType": "administrative.locality",
        "elementType": "labels.text.stroke",
        "stylers": [
          {
            "weight": "0.73"
          }
        ]
      },
      {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [
          {
            "color": "#f2f2f2"
          }
        ]
      },
      {
        "featureType": "landscape.natural.landcover",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "visibility": "on"
          },
          {
            "color": "##000000"
          },
          {
            "saturation": "0"
          }
        ]
      },
      {
        "featureType": "landscape.natural.landcover",
        "elementType": "labels.text",
        "stylers": [
          {
            "visibility": "simplified"
          },
          {
            "color": "##000000"
          }
        ]
      },
      {
        "featureType": "landscape.natural.landcover",
        "elementType": "labels.icon",
        "stylers": [
          {
            "color": "##000000"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "poi.business",
        "elementType": "all",
        "stylers": [
          {
            "visibility": "on"
          },
          {
            "color": "#92bbc0"
          },
          {
            "weight": "0.01"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "all",
        "stylers": [
          {
            "saturation": -100
          },
          {
            "lightness": 45
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "all",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "visibility": "on"
          },
          {
            "color": "#477b7f"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "visibility": "off"
          },
          {
            "color": "#477b7f"
          }
        ]
      },
      {
        "featureType": "road.highway.controlled_access",
        "elementType": "geometry",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "road.highway.controlled_access",
        "elementType": "labels",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "road.arterial",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#47737f"
          },
          {
            "visibility": "on"
          }
        ]
      },
      {
        "featureType": "road.arterial",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "visibility": "off"
          },
          {
            "color": "#ff0000"
          }
        ]
      },
      {
        "featureType": "road.arterial",
        "elementType": "labels.icon",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "road.local",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "weight": "0.01"
          },
          {
            "visibility": "on"
          },
          {
            "color": "#477b7f"
          }
        ]
      },
      {
        "featureType": "road.local",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "visibility": "off"
          },
          {
            "color": "#ff0000"
          }
        ]
      },
      {
        "featureType": "road.local",
        "elementType": "labels.text",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "road.local",
        "elementType": "labels.icon",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
          {
            "color": "#6492a5"
          },
          {
            "visibility": "on"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#8ec6c1"
          }
        ]
      }
    ]
  }

}
