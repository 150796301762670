class SectionFrise {

  constructor () {
    this.$section = $('.section-frise')
    this.$menu = $('.section-frise-menu')
    this.$menu_link = this.$menu.find('a')
    this.$window = $(window)

    if (this.$section.length === 0) return

    new StickyController($(".section-frise-square"))

    this.$menu_link.on('click', this.handleClickMenuSectionFrise.bind(this))

    this.frise_blocks = $('.section-frise-blocks')

    $(window).bind('scroll', this.handleScrollSpy.bind(this));
  }

  handleClickMenuSectionFrise (e) {
    let anchor = $(e.currentTarget).attr('href')

    console.log('link : ', anchor)

    $([document.documentElement, document.body]).animate({
      scrollTop: $(anchor).offset().top - 200
    }, 100);
  }

  handleScrollSpy (e) {
    let windowsTop = this.$window.scrollTop();
    let middleScreenheight = windowsTop + (this.$window.height() / 2)
    let isActiveScrollSpy = false;

    this.frise_blocks.each( function (index) {
      let lineTop = $(this).offset().top - 250;
      let lineBottom = $(this).offset().top + $(this).height();

      if (windowsTop >= lineTop && middleScreenheight <= lineBottom) {
        isActiveScrollSpy = true;
        let id = $(this).attr('id');
        let navElem = $('a[href="#' + id+ '"]');
        navElem.addClass('active').siblings().removeClass( 'active' );
      }
    });

    if (isActiveScrollSpy) {
      this.$menu.addClass('sticky')
    } else {
      this.$menu.removeClass('sticky')
    }
  }
}

new SectionFrise()